import React, { useRef } from "react";

type OtpProps = {
  phoneNumber: string;
  otpErrorMessage: string;
  onOtpChange: (e: React.ChangeEvent<HTMLInputElement>, idx: number) => void;
  onOtpSubmit: () => void;
  onBack: () => void;
};

const Otp: React.FC<OtpProps> = ({
  phoneNumber,
  otpErrorMessage,
  onOtpChange,
  onOtpSubmit,
  onBack,
}) => {
  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const value = e.target.value;
    if (!/[0-9]/.test(value)) {
      return;
    }

    onOtpChange(e, idx);

    if (value && idx < otpRefs.current.length - 1) {
      otpRefs.current[idx + 1]?.focus();
    }
  };

  return (
    <div className="p-8 rounded-xl shadow-xl w-[90%] max-w-lg relative">
      <button
        className="absolute top-3 left-3 text-gray-500 hover:text-gray-700"
        onClick={onBack}
      >
        &#8592; Back
      </button>
      <h2 className="text-2xl font-bold text-center mb-2 text-purple-700">Verify OTP</h2>
      <p className="text-sm text-gray-600 text-center mb-6">
        An OTP has been sent to +91-{phoneNumber}. Please enter it below.
      </p>
      <div className="flex justify-center space-x-2 mb-4">
        {Array(6)
          .fill("")
          .map((_, idx) => (
            <input
              key={idx}
              type="text"
              maxLength={1}
              className="border border-gray-300 focus:border-purple-600 focus:ring-2 focus:ring-purple-200 rounded-lg w-12 h-12 text-center text-gray-700 text-xl"
              ref={(el) => (otpRefs.current[idx] = el)}
              onChange={(e) => handleOtpChange(e, idx)}
            />
          ))}
      </div>
      {otpErrorMessage && <p className="text-red-500 text-sm">{otpErrorMessage}</p>}
      <button
        className="bg-purple-700 text-white py-3 px-6 rounded-lg w-full hover:bg-purple-500 transition-all mb-4"
        onClick={onOtpSubmit}
      >
        Login
      </button>
    </div>
  );
};

export default Otp;