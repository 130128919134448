import axios from 'axios';

const API_BASE_URL = "https://www.astroculture.in/";
const API_ASTRO_URL = "http://13.234.67.163:7000"; // TODO: WILL BE REMOVED

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const astroApiClient = axios.create({
  baseURL: API_ASTRO_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

enum API_METHOD {
  GET = 'get',
  DELETE = 'delete',
  PUT = 'put',
  POST = 'post',
}

const formatApiResponse = (isError: boolean, data: any = {}) => {
  return {
    isError,
    data,
  };
};

const handleApiResponse = async (promise: Promise<any>) => {
  try {
    const response = await promise;
    console.log('response in apiService', response?.data);
    return formatApiResponse(false, response?.data);
  } catch (error) {
    console.log('error in apiService', error);
    if (error.response?.status === 401) {
      // Handle unauthorized error
      return formatApiResponse(true);
    }
    if (error.response) {
      return formatApiResponse(true, error.response?.data);
    } else if (error.request) {
      return formatApiResponse(true, error.request?.data);
    } else {
      return formatApiResponse(true, { message: 'Unknown', error: error });
    }
  }
};

const apiService = async (
  method: API_METHOD,
  url: string,
  config = {},
  data = {},
  service?: string,
) => {
    const sessionId = localStorage.getItem('session-id');
  const headers = {
    'session-id': sessionId?sessionId:'',
  };

  const requestConfig = {
    method,
    url,
    headers,
    ...config,
    data,
  };

  let response;
  switch (service) {
    case 'Astro':
      console.log('using Astro service');
      response = handleApiResponse(astroApiClient(requestConfig));
      break;
    default:
      response = handleApiResponse(apiClient(requestConfig));
      break;
  }
  return response;
};

const _get = (url: string, config = {}, service?: string) => {
  return apiService(API_METHOD.GET, url, config, {}, service);
};

const _delete = (url: string, config = {}, service?: string) => {
  return apiService(API_METHOD.DELETE, url, config, {}, service);
};

const _put = (url: string, data = {}, config = {}, service?: string) => {
  return apiService(API_METHOD.PUT, url, config, data, service);
};

const _post = (url: string, data = {}, config = {}, service?: string) => {
  return apiService(API_METHOD.POST, url, config, data, service);
};

export { _delete, _get, _post, _put };