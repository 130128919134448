import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/footer.tsx';
import Header from './components/header/header.tsx';
import HomePage from './pages/home/homePage.tsx';
import ResourcesPage from './pages/products/productsPage.tsx';
import ProductsPage from './pages/resources/resourcesPage.tsx';
import DeleteUser from './pages/deleteUser/DeleteUser.tsx';
import Auth from './pages/login/Auth.tsx';
//
function App() {
  return (
    <div className="app-container">
      <div className="hero-container">
        <Header />
        <main className="content w-full md:px-24">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            {/* <Route path="/delete-user" element={<DeleteUser />} /> */}
            <Route path = '/delete-account' element= {<Auth/>}/>
          </Routes>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default App;
