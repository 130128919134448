import React, { useState, useRef, useEffect } from "react";
import { generateOtp, validateOtp, deleteUser } from "../../api/api.ts";
import Login from "./phoneNumber.tsx";
import Otp from "./otp.tsx";
import ConfirmationPopup from "./ConfirmationPopup.tsx";

const Auth = () => {
  const [showLogin, setShowLogin] = useState(true); // For login component
  const [showOtp, setShowOtp] = useState(false); // For OTP component
  const [showSuccess, setShowSuccess] = useState(false); // For success buttons
  const [showConfirmation, setShowConfirmation] = useState(false); // For confirmation popup
  const [phoneNumber, setPhoneNumber] = useState(""); // Store phone number
  const [otp, setOtp] = useState(""); // Store OTP
  const [errorMessage, setErrorMessage] = useState(""); // Error message for phone number
  const [otpErrorMessage, setOtpErrorMessage] = useState(""); // Error message for OTP

  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handlePhoneSubmit = async () => {
    if (phoneNumber.trim().length !== 10) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      const response = await generateOtp(phoneNumber);
      console.log('response in auth', response);
      if (response.isError) {
        setErrorMessage(response.data.message || "An error occurred. Please try again.");
        return;
      }
      setShowLogin(false);
      setShowOtp(true);
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleOtpSubmit = async () => {
    if (otp.trim().length !== 6) {
      setOtpErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      const response = await validateOtp(phoneNumber, otp);
      console.log('response in auth', response);
      if (response.isError) {
        setOtpErrorMessage(response.data.message || "An error occurred. Please try again.");
        return;
      }
      const sessionId = response.data.sessionId;
      localStorage.setItem('session-id', sessionId);
      setShowOtp(false);
      setShowSuccess(true);
    } catch (error) {
      setOtpErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleOnChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 10) {
      return;
    }
    setPhoneNumber(e.target.value);
    setErrorMessage(""); // Clear previous error
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const value = e.target.value;
    if (!/[0-9]/.test(value)) {
      return;
    }

    const newOtp = otp.split('');
    newOtp[idx] = value;
    setOtp(newOtp.join(''));

    if (value && idx < otpRefs.current.length - 1) {
      otpRefs.current[idx + 1]?.focus();
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await deleteUser();
      console.log('response in auth', response);
      if (response.isError) {
        alert(response.data.message || "An error occurred. Please try again.");
        return;
      }
      localStorage.setItem('session-id', '');
      window.location.href = "/";
    } catch (error) {
      alert("An error occurred. Please try again.");
    }
  };

  const handleConfirmDelete = () => {
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDeleteUser = () => {
    setShowConfirmation(false);
    handleDeleteUser();
  };
  const sessionId = localStorage.getItem('session-id');

  useEffect(() => {
    if(sessionId){
      setShowLogin(false);
      setShowOtp(false);
      setShowSuccess(true);
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {showLogin && (
        <Login
          phoneNumber={phoneNumber}
          errorMessage={errorMessage}
          onPhoneNumberChange={handleOnChangePhoneNumber}
          onPhoneSubmit={handlePhoneSubmit}
        />
      )}

      {showOtp && (
        <Otp
          phoneNumber={phoneNumber}
          otpErrorMessage={otpErrorMessage}
          onOtpChange={handleOtpChange}
          onOtpSubmit={handleOtpSubmit}
          onBack={() => {
            setShowOtp(false);
            setShowLogin(true);
          }}
        />
      )}

{showSuccess && (
        <div className="p-8 rounded-xl shadow-xl w-[90%] max-w-lg text-center">
          <h2 className="text-2xl font-bold text-center mb-4 text-purple-700">Logged In </h2>
          <button
            className="bg-purple-700 text-white py-3 px-6 rounded-lg w-full hover:bg-purple-500 transition-all mb-4"
            onClick={() => window.location.href = "/"}
          >
            Go to Home
          </button>
          <button
            className="bg-red-700 text-white py-3 px-6 rounded-lg w-full hover:bg-red-500 transition-all"
            onClick={handleConfirmDelete}
          >
            Delete User Account
          </button>
        </div>
      )}

{showConfirmation && (
        <ConfirmationPopup
          message="Are you sure? All your data will be lost."
          onConfirm={handleConfirmDeleteUser}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default Auth;