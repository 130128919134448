import { _delete, _post } from "./apiClient.ts";
import { API_ROUTE } from "./apiRoutes.ts";

export const generateOtp = async (phoneNumber: string) => {
    console.log('phoneNumber in generateOtp', phoneNumber);
    return await _post(API_ROUTE.GENERATE_OTP, { phoneNumber });
};

export const validateOtp = async (phoneNumber: string, otp: string) => {
    return await _post(API_ROUTE.VALIDATE_OTP, { phoneNumber, otp });
  };

  export const deleteUser = async () => {
    return await _delete(API_ROUTE.DELETE_USER);
  };