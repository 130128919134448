import React from "react";

type LoginProps = {
  phoneNumber: string;
  errorMessage: string;
  onPhoneNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPhoneSubmit: () => void;
};

const Login: React.FC<LoginProps> = ({
  phoneNumber,
  errorMessage,
  onPhoneNumberChange,
  onPhoneSubmit,
}) => {
  return (
    <div className="p-8 rounded-xl shadow-xl w-[90%] max-w-lg">
      <h2 className="text-2xl font-bold text-center mb-2 text-purple-700">Login</h2>
      <div className="relative mb-2">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-700">+91</span>
        <input
          type="text"
          className="pl-12 border border-gray-300 focus:border-purple-600 focus:ring-2 focus:ring-purple-200 rounded-lg p-3 w-full text-gray-700"
          placeholder="Enter your mobile number"
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <button
        className="bg-purple-700 text-white py-3 px-6 rounded-lg w-full hover:bg-purple-500 transition-all"
        onClick={onPhoneSubmit}
      >
        Get OTP
      </button>
    </div>
  );
};

export default Login;