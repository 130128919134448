import React from "react";

type ConfirmationPopupProps = {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-xl shadow-xl w-[90%] max-w-md text-center">
        <p className="text-lg mb-4">{message}</p>
        <div className="flex justify-center space-x-4">
          <button
            className=" bg-[#ccc] text-white py-2 px-4 rounded-lg hover:bg-red-500 transition-all"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-[#6754BD] text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition-all"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;